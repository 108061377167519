import axios from 'axios';
import { useEffect, useState } from 'react';
import { API_HEADERS, ApiKey, ApiServer, ScenarioCmsServer, ServerAddress, StrapiAddress } from '../const/Server';

export const STRAPI_MAIN = ServerAddress
export const STRAPI_HEADERS = {
    'Authorization': `Bearer ${ApiKey}`,
    "Content-Type": "application/json"
}

const useStrapiData = (uri) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(StrapiAddress + uri, {
                    headers: STRAPI_HEADERS
                });
                setData(response?.data?.data || response?.data || []);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchData();
    }, [uri]);

    return { data, loading, error };
};
export default useStrapiData;

export function GetImageSource(image) {
    let result = image?.data?.attributes || image?.data || image || { url: "xxxx" }
    return STRAPI_MAIN + result?.url
}

export function GetWebviewUrlFromFilePrefix(filePrefix) {
    if (!filePrefix) return false
    return ScenarioCmsServer + filePrefix + "/web/index.htm"
}

export function GetDownloadUrlFromFilePrefix(filePrefix, subDir, ext = ".zip") {
    if (!filePrefix) return false
    let result = `${ScenarioCmsServer}${filePrefix}/${subDir}/${filePrefix}${ext}`
    return result
}

export function DownloadFiles(files = []) {
    console.log("DownloadFiles", files)
    files.forEach(i => {
        let uri = i.attributes?.url
        let url = STRAPI_MAIN + uri
        //DOwnload url
        window.open(url, "_blank")
    })
}

export function CreateDepartment(department) {
    return new Promise((resolve, reject) => {
        axios({
            url: ApiServer + `/departments/add`,
            method: "POST",

            data: JSON.stringify({ data: department, test: 1 }),
            headers: API_HEADERS,
        })
            .then((res) => {
                resolve(res.data)
            })
            .catch(err => {
                alert("Fehler beim Speichern.")
                console.log(err)
                reject(err)
            })
    })
}