import React from "react"
import { STRAPI_EDIT_HOMEPAGE } from "../../const/Server"
import { useLoginState } from "../../service/Auth"
import useStrapiData, { GetImageSource } from "../../service/Strapi"
import EditInStrapi from "../atoms/boxes/EditInStrapi"
import NoticeBox from "../molecules/NoticeBox/NoticeBox"
import Footer from "../organismus/Footer/Footer"
import TextPageTemplate from "../templates/TextPageTemplate/TextPageTemplate"
import "./HomePage.scss"

function HomePage({ }) {
    let { data, loading, error } = useStrapiData("/homepage?populate=titleImage")
    let isLoggedIn = useLoginState()
    if (loading) return <h2>Die Startseite wird geladen...</h2>
    if (error) {
        console.log(error)
        if (isLoggedIn) {
            return (
                <div>
                    <h1>Anmeldung abgelaufen</h1>
                    <p>Melden Sie sich bitte erneut an, um auf die Anwendung zu zugreifen.</p>
                </div>)
        }
        return (
            <div>
                <h1>Serverfehler</h1>
                <p>Bitte kontaktieren Sie konrad.mayer@hessenapp.de oder Versuchen Sie es später erneut.</p>
            </div>)
    }
    let { title, text, titleImage, textTitle, notice, noticeColor, noticeBackgroundColor, noticeUrl } = data?.attributes
    let src = GetImageSource(titleImage)
    return (
        <div>
            <EditInStrapi url={STRAPI_EDIT_HOMEPAGE} />
            <NoticeBox color={noticeColor} url={noticeUrl} backgroundColor={noticeBackgroundColor}>{notice}</NoticeBox>
            <TextPageTemplate showHeader title={(title || "Startseite")}>
                <div className="cont">
                    <img className="w-100 mb-4" src={src} />
                    <h2>{textTitle}</h2>
                    <p>{text}</p>
                </div>
                <Footer />
            </TextPageTemplate>
        </div>
    )
}
export default HomePage