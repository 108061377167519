import React from "react"
import { Link } from "react-router-dom"
import "./Header.scss"

function Header({ }) {
    return (
        <div className="Header my-lg-5">
            <header>
                <div className="items">
                    <Link to="/leichte-sprache" title="Leichte Sprache"><i className="fa-solid fa-message big"></i></Link>
                    <Link to="/barrierefreiheit" title="Barrierefreiheit"><i className="fa-solid fa-person-walking-with-cane big"></i></Link>
                    <a href="https://hlfs.hessen.de" target="_blank">hlfs.hessen.de <i className="fa-solid fa-arrow-up-right-from-square"></i></a>
                </div>
            </header>
        </div>
    )
}
export default Header