import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { DeleteDepartment } from "../../../service/DeparmentsService"
import BorderBox from "../../atoms/boxes/BorderBox"
import Checkbox from "../../atoms/inputs/Checkbox/Checkbox"
import UserItem from "../../molecules/UserItem/UserItem"
import ScenarioPermissions from "../../organismus/permissions/ScenarioPermissions/ScenarioPermissions"
import TextPageTemplate from "../TextPageTemplate/TextPageTemplate"
import "./UserPageTemplate.scss"

function UserPageTemplate(props) {
    const navigate = useNavigate();
    let { title, onChange, attributes, subComponent, hideParentSystem = false, id, hidePermissionSystem = false, showDeleteButton = false } = props
    let user = attributes || {}
    let { username, email, image, permission, parentDepartment = {}, canCreateUsers, childDepartments = { data: [] } } = user
    let parentDepartmentData = parentDepartment?.data
    function handleChange(key, value) {
        console.log("New key value: ", key + " = " + value)
        onChange && onChange({ key, value })
    }
    async function handleDelete() {
        await DeleteDepartment(id)
        navigate("/feuerwehr")
    }

    const [CanCreateUsers, setCanCreateUsers] = useState(canCreateUsers)

    function toggleCanCreateUsers() {
        handleChange("permission.canCreateUser", !CanCreateUsers)
        setCanCreateUsers(!CanCreateUsers)
    }

    console.log(user)
    return (
        <TextPageTemplate subComponent={subComponent} title={title || username} className="UserPageTemplate" >

            {/* Optionen die unten gelistet werden */}
            <h2>Allgemeines:</h2>
            <BorderBox>
                {options.map((i, k) =>
                    <div className="option" key={k}>
                        <span className="lable">{i.title}:</span>
                        <input {...i} onChange={(e) => handleChange(i.key, e.target.value)} value={user[i.key]} />
                        {i.notice && <small><b>Hinweis:</b> {i.notice}</small>}
                    </div>
                )}
            </BorderBox>

            {!hideParentSystem && <BorderBox>
                <h2>Zugehörigkeit:</h2>
                <div className="option" >
                    <span className="lable">Gehört zu:</span>
                    {!parentDepartmentData ?
                        <p>Gehört zu keiner übergeordneten Feuerwehr.</p>
                        :
                        <table >
                            <UserItem className="rounded" {...parentDepartmentData} />
                        </table>
                    }
                </div>
                <div className="option" >
                    <span className="lable">Untergeordnete Feuerwehren:</span>
                    <table>
                        {childDepartments.data.map((i, k) => <UserItem {...i} />)}
                        {childDepartments.data.length == 0 && <p>Keine untergeordneten Feuerwehren.</p>}
                    </table>
                </div>
            </BorderBox>
            }

            {!hidePermissionSystem &&
                <>
                    <BorderBox>
                        <h2>Nutzerverwaltung:</h2>
                        {/* Statische Funktionen */}
                        <div className="d-flex flex-row cursor-pointer" onClick={toggleCanCreateUsers} >
                            <div className="pe-1">
                                <Checkbox isActive={CanCreateUsers} />
                            </div>
                            {CanCreateUsers ?
                                <label><b>Kann</b> untergeordnete Feuerwehren erstellen und verwalten.</label> :
                                <label>Kann <b>keine</b> untergeordnete Feuerwehren erstellen und verwalten.</label>
                            }
                        </div>
                    </BorderBox>
                    <BorderBox>
                        <h2>Berechtigte Szenarien:</h2>
                        <div>
                            <div className="col-12 col-lg-12 col-xl-10 col-xxl-8">
                                <ScenarioPermissions targetDepartmentId={id} />
                            </div>
                        </div>
                    </BorderBox>
                </>
            }
            {showDeleteButton &&
                <>
                    <div className="d-flex">
                        <button><i class="fa-solid fa-shield-check"></i> Änderungen gespeichert</button>
                        <button className="red" onClick={handleDelete}>
                            <i class="fa-solid fa-trash"></i>
                            Feuerwehr löschen
                        </button>
                    </div>
                    <p><small><b>Hinweis:</b> Änderungen werden automatisch gespeichert.</small></p>
                </>
            }
        </TextPageTemplate >
    )
}
export default UserPageTemplate

const options = [
    {
        key: "username",
        title: "Feuerwehr Name",
        type: null,
        autoComplete: "organization",
    },
    {
        key: "email",
        title: "E-Mail",
        type: "mail",
        notice: "Private E-Mails sind strengstens untersagt.",
        autoComplete: "email",
    },
]