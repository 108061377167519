import React from "react"
import "./Checkbox.scss"

function Checkbox({ isActive, onClick, loading, error }) {


    let icon = "fa-times-circle"

    if (isActive) icon = "fa-check-circle"
    if (loading) icon = "fa-circle-notch fa-spin"
    if (error) icon = "fa-circle-exclamation fa-fade"

    return (
        <div className="Checkbox" onClick={onClick}>

            <i className={"fas " + icon}></i>

        </div>
    )
}
export default Checkbox