import React from "react"
import { useEducationsWithScenarios } from "../../../../service/Scenario"
import EducationPermissions from "../../../molecules/permissions/EducationPermissions/EducationPermissions"
import "./ScenarioPermissions.scss"

function ScenarioPermissions({ targetDepartmentId }) {
    let { data = [], loading, error } = useEducationsWithScenarios({ targetDepartmentId })
    if (loading) return <p>Loading...</p>
    if (error || !data) return <p>Error: {Error}</p>

    let educations = [];
    let educationIds = [];

    data.forEach((scenario) => {
        let education = scenario?.attributes?.education?.data;
        let educationID = education?.id;

        if (!educationIds.includes(educationID)) {
            // Wenn die Bildung noch nicht in der Liste ist, füge sie hinzu
            educationIds.push(educationID);
            educations.push({
                attributes: {
                    ...education.attributes,
                    scenarios: [scenario], // Erstelle eine neue Liste mit dem aktuellen Szenario unter education.attributes.scenarios
                },
            });
        } else {
            // Wenn die Bildung bereits in der Liste ist, füge das Szenario zu ihrer Liste hinzu
            let index = educationIds.findIndex((id) => id === educationID);
            educations[index].attributes.scenarios.push(scenario);
        }
    });

    return <div className="DepartmentPermissions mb-4">
        {educations?.map && educations.map((i, k) => <EducationPermissions {...i.attributes} targetDepartmentId={targetDepartmentId} />)}
    </div>
}
export default ScenarioPermissions