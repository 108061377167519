import React from "react"
import { Link } from "react-router-dom"
import "./Footer.scss"

function Footer({ }) {
    return (
        <footer className="Footer">
            {urls.map((i, k) =>
                <div key={k}>
                    <Link  {...i} />
                </div>
            )}
        </footer>
    )
}
export default Footer

const urls = [
    {
        children: "Impressum",
        to: "/impressum",
    },
    {
        children: "Datenschutzerklärung",
        to: "/datenschutz",
    },
    {
        children: "Erklärung zur Barrierefreiheit",
        to: "/barrierefreiheit",
    },
]