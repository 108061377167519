import React from "react";
import { useNavigate } from "react-router-dom";
import { JWT } from "../../../const/Server";
import { decodeJWTToken } from "../../../service/Auth";
import { DeleteDepartment } from "../../../service/DeparmentsService";
import "./UserItem.scss";

function UserItem({ attributes, className, id, }) {
    let { username = "<KEIN_USERNAME?>", email = "<KEINE_EMAIL?>", } = attributes || {};

    const navigate = useNavigate();
    let data = decodeJWTToken(JWT) || {}
    let currentUserId = data.id
    let isOwnAccount = currentUserId == id

    async function handleDelete() {
        await DeleteDepartment(id)
        navigate("/feuerwehr")
    }

    function handleRowClick() {
        navigate(`/feuerwehr/${id}`);
    }

    const handleEmailClick = (e) => {
        e.stopPropagation();
    };

    return (
        <tr className={"UserItem " + className} onClick={handleRowClick}>

            <td className="name">
                {username}
                {isOwnAccount && <small><b>(Eigener Account)</b></small>}
            </td>
            <td className="email">{email}</td>

            <td>
                {/* Bearbeiten */}

                {/* Löschen */}
                <a className="mx-1" title="Feuerwehr löschen" onClick={handleDelete}>
                    <i className="fa-solid fa-trash"></i>
                </a>

                {/* E-Mail */}
                <a className="mx-1" title={"Email an " + email} href={"mailto:" + email} target="_blank" onClick={handleEmailClick}>
                    <i className="fa-solid fa-envelope"></i>
                </a>
            </td>
        </tr>
    )
}
export default UserItem