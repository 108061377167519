import React from "react"
import "./DetailTags.scss"

function DetailTags({ level, code, icon }) {

    let showLevel = level != 0

    if (!level) showLevel = false


    return (
        <div className="DetailTags">

            {code && <div className="code">{code}</div>}

            {showLevel && <div className="level">
                Stufe {level}
            </div>}

            {/* Icon, wird bei den Premissions angezeigt */}
            {icon && <div className="icon" >
                <i className={`fas fa-${icon}`} />
            </div>}

        </div>
    )
}
export default DetailTags