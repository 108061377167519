import React from "react"
import { MOBILE_PADDING } from "../../../const/Style"
import Header from "../../organismus/Header/Header"
import "./TextPageTemplate.scss"

function TextPageTemplate({ title, className, children, subComponent, search = false, onSearch = () => { }, showHeader = false }) {
    let brakePoint = "lg"
    return (
        <div className={"TextPageTemplate " + (className || "")}>
            {showHeader ? <Header /> : <div style={{ height: 66, }} />}
            <div className={`inner  p-${brakePoint}-0 ` + MOBILE_PADDING}>
                <div className="d-flex flex-row align-items-center justify-content-between mb-4">
                    <div className="d-flex flex-row align-items-center">
                        <h1>{title || "Unterseite"}</h1>
                        {search && <i className="fa-solid fa-search"></i>}
                    </div>
                    <div className="subComponent">
                        {subComponent}
                    </div>
                </div>
                {children}
            </div>
        </div>
    )
}
export default TextPageTemplate