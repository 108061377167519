import axios from "axios"
import React, { useState } from "react"
import { ApiServer } from "../../../../const/Server"
import { isEmail } from "../../../../utils/Regex"
import NoServerConnectionBox from "../../../molecules/errors/NoServerConnectionBox/NoServerConnectionBox"
import "./ResetPasswordPage.scss"

function ResetPasswordPage({ }) {

    const [Loading, setLoading] = useState(false)
    const [Finish, setFinish] = useState(false)
    const [Email, setEmail] = useState("")
    const [Error, setError] = useState(false)

    async function resetPassword() {
        setLoading(true)
        setError(false)
        if (isEmail(Email)) {
            axios.post(ApiServer + "/auth/reset", { email: Email })
                .then(() => {
                    setLoading(false)
                })
                .catch(() => {
                    setError("Fehlgeschlagen. Bitte versuchen Sie es später erneut.")
                })
                .finally(() => {
                    setFinish(true)
                })
        } else {
            setError("Bitte geben Sie eine gültige E-Mail-Adresse ein.")
        }
    }

    let result

    if (Finish) {
        result = <>
            <h1>Emails prüfen</h1>
            <p>Falls eine Konto mit der Adresse <b>{Email}</b> existiert, haben wir Ihnen eine Email zum zurücksetzen des Passwortes gesendet.</p>
        </>
    } else {
        result = <>
            <NoServerConnectionBox />
            <h1>Passwort vergessen</h1>
            <p>Bitte geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen.</p>
            <div className="mt-5">
                <input
                    value={Email}
                    onChange={e => setEmail(e.target.value)}
                    type="email"
                    id="email"
                    name="email"
                    autoComplete="username"
                    placeholder="E-Mail-Adresse eingeben"
                />
                {Error && <p className="text-danger">{Error}</p>}
                <button onClick={resetPassword}>Passwort zurücksetzen</button>
                {Loading && <p className="text-muted">Bitte warten...</p>}
            </div>
        </>
    }


    return <div className="ResetPasswordPage p-4">{result}</div>
}
export default ResetPasswordPage