import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { StrapiLoginUrl } from "../../../const/Server"
import { useLoginState } from "../../../service/Auth"
import { isAdmin, showUserManager } from "../../../service/Permissions"
import { useEducations } from "../../../service/Scenario"
import Logo from "../../atoms/Logo/Logo"
import ErrorTextNetwork from "../../molecules/errors/ErrorTextNewtork/ErrorTextNetwork"
import "./Sidebar.scss"


function Sidebar({ onChange, className = "" }) {
    let isLoggedIn = useLoginState()
    return (
        <div className={"Sidebar me-0 me-lg-5 p-4" + className} onClick={onChange}>
            {/* Logo */}
            <div className="pt-5 ps-4">
                <Logo className="d-none d-md-flex" />
            </div>

            <div className="inner">
                {/* //Wenn der Nutzer angemeldet ist, liste alle Lehrgänge/Ausbildungen auf */}
                {isLoggedIn && <EducationList />}

                {/* Buttons ganz Unten */}
                <div className="pt-5">
                    <div className="d-flex flex-column">
                        <Link to="/kontakt">
                            <button>Kontakt</button>
                        </Link>

                        {/* Login / Logount Button */}
                        {isLoggedIn ?
                            <Link to="/logout">
                                <button>Abmelden</button>
                            </Link>
                            :
                            <Link to="/login">
                                <button>Anmelden</button>
                            </Link>
                        }
                    </div>
                </div>
            </div>
            <div style={{ opacity: 0.5 }} className="ms-1">
                <small>© Hessische Landesfeuerwehrschule</small>
            </div>
        </div>
    )
}
export default Sidebar

function EducationList() {
    let { data = [], loading, error } = useEducations()
    const [userManager, setUserManager] = useState(false);
    const [admin, setAdmin] = useState(false);
    const [updateTimer, setUpdateTimer] = useState(null);

    useEffect(() => {
        const fetchData = () => {
            const userManagerData = showUserManager(); // Assuming showUserManager fetches data from backend
            const adminData = isAdmin(); // Assuming isAdmin fetches data from backend
            setUserManager(userManagerData);
            setAdmin(adminData);
        };

        const fetchDataWithDelay = () => {
            setTimeout(() => {
                fetchData(); // Fetch data after 1 second
                setUpdateTimer(setInterval(fetchData, 30000)); // Start interval after initial delay
            }, 1000); // Wait for 1 second before fetching data
        };

        fetchDataWithDelay(); // Initial data fetch

        return () => {
            clearInterval(updateTimer); // Clean up the interval on component unmount
        };
    }, []); // Empty dependency array ensures the effect runs only once on component mount

    return (
        <div className="EducationList">
            <Link to="/home" >Übersicht</Link>
            <Link to="/service">Service</Link>
            {userManager && <Link to="/feuerwehr">Feuerwehren verwalten</Link>}
            {admin && <a href={StrapiLoginUrl} target="_blank">Strapi Datenbank</a>}

            <hr />

            <h4>Ausbildungen:</h4>
            {error && <ErrorTextNetwork />}
            {data.map((item, index) => <EducationListItem {...item?.attributes} key={index} />)}
        </div>
    );
}

//Ein Eintrag der EducationList
function EducationListItem({ title, uri }) {
    return <Link className="EducationListItem" to={"/home/" + uri}>{title}</Link>
}