
import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import { STRAPI_EDIT_FAQ } from '../../../const/Server'
import { GetFaqButtons } from "../../../service/FaqService"
import { isAdmin } from '../../../service/Permissions'
import FaqButton from "../../atoms/FaqButton/FaqButton"
import BorderBox from "../../atoms/boxes/BorderBox"
import "./FaqBox.scss"

function FaqBox() {
    const [loading, setLoading] = useState(true);
    const [buttons, setButtons] = useState([]);

    useEffect(() => {
        // Daten asynchron laden
        async function fetchData() {
            try {
                const faqButtons = await GetFaqButtons();
                setButtons(faqButtons);
                setLoading(false);
            } catch (error) {
                console.error('Fehler beim Laden der FAQ-Buttons:', error);
            }
        }

        fetchData();
    }, []); // Leeres Abhängigkeitsarray sorgt dafür, dass dies nur einmal beim Rendern aufgerufen wird

    return (
        <div className="FaqBox">
            <div className="mt-5">
                <h2>Häufig gestellte Fragen:</h2>
                {isAdmin() && <small><a href={STRAPI_EDIT_FAQ} target='_blank'>FAQ in Strapi bearbeiten. 🖊️</a></small>}
                {loading ? (
                    // Anzeigen eines Loaders, solange die Daten geladen werden
                    <p>Wird geladen...</p>
                ) : (
                    <BorderBox>
                        {buttons.map && buttons.map((i, k) => (
                            <FaqButton {...i.attributes} key={k} />
                        ))}
                    </BorderBox>
                )}

                <div>
                    <h3 className="mb-2">Benötigen Sie Unterstützung?</h3>
                    <Link to="/service">
                        <button>Service Bereich öffnen</button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default FaqBox;
