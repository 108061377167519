import React, { useState } from "react"
import { Link } from "react-router-dom"
import { useApi } from "../../../service/API"
import { canCreateUser } from "../../../service/Permissions"
import DefaultLoader from "../../atoms/DefaultLoader/DefaultLoader"
import UserItem from "../../molecules/UserItem/UserItem"
import ErrorTextNetwork from "../../molecules/errors/ErrorTextNewtork/ErrorTextNetwork"
import TextPageTemplate from "../../templates/TextPageTemplate/TextPageTemplate"
import "./UserListPage.scss"
function UserListPage({ }) {
    const [Search, setSearch] = useState("")
    let { data, loading, error } = useApi("/departments?_q=" + Search)
    function handleChange(e) {
        setSearch(e.target.value)
    }
    if (loading) return <DefaultLoader />
    if (error) return <TextPageTemplate title={"Feuerwehren verwalten"} className="UsersPage"><ErrorTextNetwork /></TextPageTemplate>
    if (data) {
        return (
            <TextPageTemplate
                title={"Feuerwehren verwalten"}
                className="UserListPage"
                subComponent={
                    canCreateUser() && <Link to="/feuerwehr/erstellen">
                        <button className="small">
                            <i className="fa-solid fa-plus-circle" /> Hinzufügen
                        </button>
                    </Link>

                }
            >
                {data.length == 0 && Search == "" ?
                    <div>
                        <p className="pt-3">Sie verwalten derzeit keine Feuerwehren.</p>
                        {canCreateUser() && <Link to="/feuerwehr/erstellen">
                            <button>
                                <i className="fa-solid fa-plus-circle" /> Feuerwehr hinzufügen
                            </button>
                        </Link>}
                    </div>
                    :
                    <>
                        <div className="mb-4">
                            <input placeholder="Suche nach Nutzer" value={Search} onChange={handleChange}></input>
                        </div>
                        <div className="row">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Feuerwehr</th>
                                        <th>Mail</th>
                                        <th>Aktionen</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((user) => (
                                        <UserItem {...user} />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {data.length == 0 && Search != "" && <p className="pt-3 p-2">Keine Feuerwehren für '{Search}' gefunden.</p>}
                    </>
                }
            </TextPageTemplate >
        )
    }
}
export default UserListPage