import React from "react";
import { STRAPI_MAIN } from "../../../service/Strapi";
import "./DocumentList.scss";


function DocumentList({ data = [] }) {
    if (!Array.isArray(data) || !data?.length || data?.length == 0) return <p>Keine Dokumente hinterlegt.</p>
    return (
        <div className="DocumentList">
            {Array.isArray(data) && data.map((item, index) =>
                <DocumentItem
                    {...item}
                />
            )}
            {!Array.isArray(data) || data.length == 0 && < p > Keine Dokumente verfügbar.</p>}
        </div >
    );
}

function DocumentItem({ attributes, id }) {
    let { name, caption, url, size } = attributes || {}
    return (
        <a className="DocumentItem mb-2" href={STRAPI_MAIN + url} target="_blank" title={name + ` herunterladen (ca. ${size}kb)`}>
            <i className="fa-solid fa-file pe-2"></i>
            <div>
                <h3>{caption || name}</h3>
            </div>
        </a>
    );
}


export default DocumentList;
