import React from "react"
import "./NoticeBox.scss"

function NoticeBox({ children, backgroundColor = "var(--bgSub)", color = "var(--text2)", url = "#" }) {
    if (!children) return
    return (
        <a href={url} target="_blank" className="NoticeBox py-1 px-2" style={{ backgroundColor: backgroundColor }} >
            <h2 style={{ color: color }} >Aktueller Hinweis:</h2>
            <p style={{ color: color, }} className="mb-0">{children}</p>
        </a>
    )
}
export default NoticeBox