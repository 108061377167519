import React from "react"
import useStrapiData from "../../../service/Strapi"
import "./ScenarioGroupFilter.scss"


function ScenarioGroupFilter({ onFilter = () => { }, activeFilter }) {

    let { data, loading, error } = useStrapiData("/scenario-groups?sort=title:ASC")

    return (
        <div className="ScenarioGroupFilter">
            {data?.map(i => <ScenarioGroupFilterItem
                data={i.attributes}
                onFilter={onFilter}
                activeFilter={activeFilter}
            />)}
        </div>
    )
}
export default ScenarioGroupFilter




//Auswahl im HEader: A, B, C
function ScenarioGroupFilterItem({ data, onFilter = () => { }, activeFilter }) {
    let { title, createdAt, scenarios = { data: [] }, isActive, onClick = () => { }, icon, uri } = data

    let count = scenarios?.data?.length || 0
    let countText = (count != 1) ? "Szenarien" : "Szenario"

    if (uri == activeFilter) isActive = true

    return <div className={"ScenarioGroupFilterItem "} onClick={() => onFilter(isActive ? null : uri)}>
        <div
            className={"inner " + (isActive && "isActive")}
            onClick={onClick}
        >
            <div className="ps-2">
                <i className={("fa-solid fa-" + (icon || "list"))} />
            </div>

            <div>
                <span className="title">{title}</span>
                {/* <span className="counter">{count} {countText}</span> */}
            </div>
        </div>
    </div >
}