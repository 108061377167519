import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { useUserData } from "../../../service/Auth"
import { EditDepartmentEmail, EditDepartmentUsername, EditDepartmentCanCreateUsers } from "../../../service/DeparmentsService"
import useStrapiData from "../../../service/Strapi"
import DefaultLoader from "../../atoms/DefaultLoader/DefaultLoader"
import EditInStrapi from "../../atoms/boxes/EditInStrapi"
import ErrorText from "../../molecules/errors/ErrorText/ErrorText"
import UserPageTemplate from "../../templates/UserPageTemplate/UserPageTemplate"
import "./UserPage.scss"

function UserPage({ }) {
    let { id } = useParams()

    const [NewDepartmentData, setNewDepartmentData] = useState({})

    const [Changed, setChanged] = useState(false)
    const [Saved, setSaved] = useState(false)

    let ownId = useUserData().id
    let isOwnAccount = ownId == id

    async function handleChange({ key, value }) {
        setChanged(true)
        setNewDepartmentData({ ...NewDepartmentData, [key]: value })
        console.log("Changed", key, value)
        switch (key) {
            case "username":
                EditDepartmentUsername(id, value)
                break;
            case "email":
                EditDepartmentEmail(id, value)
                break;
            case "permission.canCreateUser":
                EditDepartmentCanCreateUsers(id, value)
                break;
            default:
                console.error("Unbekannter Key", key)

        }//#endregion
    }

    let endpoint = "/departments?filters[id][$eq]=" + id + "&populate[0]=parentDepartment&populate[1]=childDepartments"
    let { data, loading, error } = useStrapiData(endpoint)
    if (loading) return <DefaultLoader />
    if (error) return <ErrorText errorCode={error} />
    if (!data) return <ErrorText errorCode="Keine Daten gefunden" />
    if (data.length === 0) return <ErrorText errorCode="Keine Daten gefunden (0 Treffer)" />
    if (data.length === 1) {
        let oldData = data[0]
        let mergedData = { ...oldData.attributes, ...NewDepartmentData }

        return <>
            <EditInStrapi id={data[0].id} type="department" />
            <UserPageTemplate
                attributes={mergedData}
                id={oldData.id}
                onChange={handleChange}
                subComponent={isOwnAccount && <p>Eigener Account</p>}
                hidePermissionSystem={isOwnAccount}
                showDeleteButton={!isOwnAccount}
            />
            {isOwnAccount && <p><b>Hinweis:</b> Sie können Ihre eigenen Berechtigungen nicht anpassen.</p>}
        </>
    }
}
export default UserPage