import React from "react"
import { useNavigate } from "react-router-dom"
import { GetDownloadUrlFromFilePrefix, GetImageSource } from "../../../service/Strapi"
import EditInStrapi from "../../atoms/boxes/EditInStrapi"
import DetailTags from "../../atoms/texts/DetailTags"
import DocumentList from "../../molecules/DocumentList/DocumentList"
import WarnBox from "../../molecules/WarnBox/WarnBox"
import "./ScenarioOverviewPageTemplate.scss"

function ScenarioOverviewPageTemplate({ attributes, id }) {
    let { title, publicId, image, description, files, solutionDocuments, play, notice, duration, filePrefix, level, code, education } = attributes || {}

    const isPlayable = filePrefix
    const navigate = useNavigate();
    let { web, windows, ios } = play || {}

    function handlePlay() {
        let page = "/webview"
        let params = { scenario: attributes }
        navigate(page, { state: params })
    }


    function download(type) {
        let ext = ""

        switch (type) {
            case "vr":
                ext = ".zip"
                break;
            case "windows":
                ext = ".exe"
                break;
            case "mac":
                ext = ".tar.bz2"
                break;
            case "web_download":
                ext = ".zip"
                break;
        }
        let downloadUrl = GetDownloadUrlFromFilePrefix(filePrefix, type, ext)
        window.open(downloadUrl, "_blank")
    }

    let educationDocuments = education?.data?.attributes?.documents || []
    let scenarioDocuments = files?.data || []
    let mergedDocuments = [...educationDocuments, ...scenarioDocuments]

    return (
        <div className="ScenarioOverviewPageTemplate">
            <EditInStrapi id={id} type={"scenario"} />
            <div>
                <div className="coverImage ratio ratio-21x9" style={{ backgroundImage: `url(${GetImageSource(image)})` }}>
                </div>
            </div>

            <div className="p-4">
                <DetailTags code={code} level={level} />
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <h1>{title}</h1>

                    {isPlayable ?
                        <button className="play" onClick={handlePlay}>
                            <i className="fa-solid fa-play me-0 me-lg-2"></i>
                            <span className="d-none d-md-inline">Szenario starten</span>
                        </button>
                        :
                        <button className="play disabled" disabled>
                            <i className="fa-solid fa-play me-0 me-lg-2"></i>
                            <span className="d-none d-md-inline">Nicht verfügbar</span>
                        </button>
                    }
                </div>

                <hr />

                <WarnBox>{notice}</WarnBox>

                <div className="mt-4">
                    <div className="mb-4">
                        <h2>Benötigte Dokumente:</h2>
                        <DocumentList data={mergedDocuments} />
                    </div>
                    <div className="mb-4">
                        <h2>Lösungsdokumente:</h2>
                        <DocumentList data={solutionDocuments?.data} />
                    </div>
                    <div className="mb-4">
                        <h2>Dauer:</h2>
                        {duration ? <p>Ca. {duration} min.</p> : <p>Keine Angabe</p>}
                    </div>
                    <div className="mb-4">
                        <h2>Beschreibung:</h2>
                        <p>{description || "Keine Beschreibung verfügbar."}</p>
                    </div>
                    <div className="mb-4 d-flex flex-column align-items-start">
                        <h2>Downloads:</h2>
                        <button onClick={() => download("vr")}>
                            <i class="fa-solid fa-vr-cardboard"></i>
                            VR-Brille
                        </button>

                        <button onClick={() => download("windows")}>
                            <i class="fa-brands fa-windows"></i>
                            Windows
                        </button>

                        <button onClick={() => download("mac")}>
                            <i class="fa-brands fa-apple"></i>
                            MacOS
                        </button>
                        <button onClick={() => download("web_download")}>
                            <i class="fa-solid fa-mobile"></i>
                            Mobile Endgeräte
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ScenarioOverviewPageTemplate