import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useEducations } from "../../../service/Scenario";
import DefaultLoader from "../../atoms/DefaultLoader/DefaultLoader";
import FaqBox from "../../molecules/FaqBox/FaqBox";
import ErrorTextNetwork from "../../molecules/errors/ErrorTextNewtork/ErrorTextNetwork";
import TextPageTemplate from "../../templates/TextPageTemplate/TextPageTemplate";
import "./WelcomePage.scss";

function WelcomePage({ }) {
    const [greeting, setGreeting] = useState('Hallo!');

    useEffect(() => {
        const currentHour = new Date().getHours();
        if (currentHour >= 5 && currentHour < 12) {
            setGreeting('Guten Morgen! ☀️');
        } else if (currentHour >= 12 && currentHour < 18) {
            setGreeting('Guten Tag! ☀️');
        } else if (currentHour >= 18 && currentHour < 22) {
            setGreeting('Guten Abend! 🌆');
        } else {
            setGreeting('Gute Nacht! 🌙');
        }
    }, []);

    return (
        <TextPageTemplate title={greeting} className="WelcomePage">
            <div className="col-12 col-lg-8 col-md-8">
                <hr />
                <div className="mb-5 mt-5 pb-5">
                    <h2 className="mb-1">Ausbildungen</h2>
                    <p>Bitte wählen Sie eine Ausbildung:</p>
                    <ScenarioList />
                </div>
                <FaqBox />
            </div>
        </TextPageTemplate>
    );
}

export default WelcomePage;

function ScenarioList() {
    let { data, error, loading } = useEducations()

    if (loading) return <DefaultLoader />
    if (error) return <ErrorTextNetwork />
    return <div className="row">
        {data.map((i, k) => <ScenarioListItem {...i.attributes} key={k} />)}
    </div>
}

function ScenarioListItem({ title, uri }) {
    return <Link to={"/home/" + uri} className="col-12 col-md-6 p-1">
        <div className="ScenarioListItem">
            <h2>{title}</h2>
        </div>
    </Link>
}
