import React, { useState } from "react";
import { MOBILE_PADDING } from "../../../const/Style";
import Logo from "../../atoms/Logo/Logo";
import Sidebar from "../../organismus/Sidebar/Sidebar";
import "./SidebarPageTemplate.scss";

function SidebarPageTemplate({ title, className, children }) {
    const [menuOpen, setMenuOpen] = useState(false);

    function closeMenu() { setMenuOpen(false); }
    function openMenu() { setMenuOpen(true); }
    function toggleMenu() { setMenuOpen(!menuOpen); }

    let brakePoint = "md";
    let sidebarWrapperClass = `sidebarWrapperClass col-12 col-${brakePoint}-3 `;
    let contentClass = `sidebarPageContent col-12 col-${brakePoint}-9 pe-${brakePoint}-5 p-2 p-${brakePoint}-4 `
    let sidebarClass = "d-lg-flex " + (menuOpen ? "d-flex" : "d-none d-md-flex")

    return (
        <div className={"SidebarPageTemplate " + (className || "")}>
            <div className="container-fluid p-0">

                <div className={"MobileHeader col-12 col-md-3  d-md-none " + MOBILE_PADDING}>
                    <Logo noText />
                    <i className={menuOpen ? "fas fa-times" : "fas fa-bars"} onClick={toggleMenu}></i>
                </div>


                <div className="row">
                    <div className={sidebarWrapperClass}>
                        <Sidebar onChange={closeMenu} className={sidebarClass} />
                    </div>

                    <div className={contentClass}>
                        <div className="headerTopPush" />
                        {children}
                    </div>

                    <div>
                        h1ufhjufju
                    </div>
                </div>
            </div>
        </div>
    );
}
export default SidebarPageTemplate;