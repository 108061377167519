import React from "react"
import { Link } from "react-router-dom"
import { GetImageSource } from "../../../service/Strapi"
import DetailTags from "../../atoms/texts/DetailTags"
import WarnBox from "../WarnBox/WarnBox"
import "./ScenarioItem.scss"

//Ein einzelnes VR-Szenario
function ScenarioItem({ attributes, id }) {
    let { title, publicId, image, description, documents, solutionDocuments, play, notice, duration, filePrefix, level = 0, code } = attributes || {}


    return (
        <Link to={"/scenario/" + id} className="ScenarioItem d-block mb-2 ratio ratio-21x9">
            <div className="coverImage" style={{ backgroundImage: `url(${GetImageSource(image)})` }}>
                <div className="inner">

                    {/* Hinweis */}
                    <WarnBox>{notice}</WarnBox>


                    <div className="">
                        {/* Title */}
                        <div>
                            <DetailTags code={code} level={level} />
                            <h2 className="title">{title}</h2>
                            {duration && <span className="duration" title={"Das Szenario dauert ca. " + duration} >Ca. {duration} Min.</span>}
                        </div>
                    </div>



                    {/* <DescriptionPreview description={description} /> */}

                </div>
            </div>
        </Link >
    )
}


export default ScenarioItem