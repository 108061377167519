import React from "react"
import TextPageTemplate from "../../templates/TextPageTemplate/TextPageTemplate"

function EasySpeachPage({ }) {
    return (
        <TextPageTemplate showHeader className={"EasySpeachPage"} title="Einfache Sprache" >


            <h2>Herzlich willkommen</h2>
            <p>Dies ist die Internetseite hlfs-vr.de.</p>
            <p>Die Internetseite gehört zur Landesfeuerwehrschule Hessen.</p>
            <p>Diese Website ist für Schüler und Lehrer.</p>
            <p>Daher ist die Website nicht Barrierefrei.</p>

            <hr />

            <h3>Benötigen Sie Hilfe?</h3>
            <p>Können Sie etwas auf unserer Internet-Seite nicht verstehen?</p>
            <p>Aber wollen Sie das gerne verstehen?</p>
            <p>Dann können Sie uns eine Barriere melden.</p>

            <a href={mailtoUrl}>
                <p>Hier ist der Link dazu:</p>
                <button>Link</button>
            </a>

        </TextPageTemplate>
    )
}
export default EasySpeachPage

const mailtoUrl = "mailto:ausbildung-gabc@hlfs.hessen.de?subject=Leichte%20Sprache&body=Hallo%2C%0D%0Aich%20m%C3%B6chte%20eine%20Barriere%20im%20Bereich%20%22Leichte%20Sprache%22%20melden%3A%0D%0A%0D%0A"