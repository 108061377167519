import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { login, useLoginState } from "../../../../service/Auth";
import NoServerConnectionBox from "../../../molecules/errors/NoServerConnectionBox/NoServerConnectionBox";
import ImportantWarnings from "../../../organismus/ImportantWarnings/ImportantWarnings";
import "./LoginPage.scss";

function LoginPage() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [Loading, setLoading] = useState(false)

    let isLoggedIn = useLoginState()
    let navigate = useNavigate()

    useEffect(() => {
        if (isLoggedIn) {
            navigate("/");
        }
    }, []);

    async function handleSubmit(event) {
        event.preventDefault();
        setLoading(true)
        setError("")
        login(username, password)
            .then(res => {
                console.log("Login erfolgreich")
                navigate("/home");
            })
            .catch((e) => {
                setError(e.message || "Login fehlgeschlagen");
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div className="LoginPage">
            <div className="inner col-12 col-lg-8 p-3 p-lg-5">
                {/* <Logo /> */}
                <hr className="d-none d-lg-block" />
                <ImportantWarnings />
                <NoServerConnectionBox />
                <h1>Anmeldebereich</h1>
                <p>Der Zugriff auf diese Inhalte ist nur für berechtigte Nutzer erlaubt. Bitte loggen Sie sich mit Ihrem Benutzernamen und Passwort ein.</p>


                <form>
                    <div className="mb-0">
                        <h2 htmlFor="email" className="h2">E-Mail-Adresse:</h2>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            autoComplete="username"
                            required
                            aria-required="true"
                            placeholder="E-Mail-Adresse eingeben"
                            className=""
                            onChange={e => setUsername(e.target.value)}
                        />
                    </div>

                    <div className="mb-0">
                        <label htmlFor="password" className="h2">
                            Passwort:
                        </label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            required
                            aria-required="true"
                            autoComplete="current-password"
                            placeholder="Passwort eingeben"
                            className=""
                            onChange={e => setPassword(e.target.value)}
                        />
                    </div>

                    <p className="error mb-3">{error}</p>

                    <button
                        onClick={handleSubmit}
                        type="submit"
                        disabled={Loading}
                        className={Loading ? "Loading" : ""}
                    >
                        {Loading ? "Anmeldung wird durchgeführt..." : "Anmelden"}
                    </button>
                </form>


                <div className="moreOptions">
                    <Link to="/kontakt" className="small">
                        Noch keine Zugangsdaten?
                    </Link>
                    <Link to="/passwort-vergessen" className="small">
                        Passwort vergessen?
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
