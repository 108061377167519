import axios from "axios";
import jwt_decode from 'jwt-decode';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthServer } from "../const/Server";

export const decodeJWTToken = (token = localStorage.getItem("token")) => {
    if (!token) {
        return {};
    }
    try {
        const decodedToken = jwt_decode(token);


        return decodedToken;
    } catch (error) {
        console.error('Fehler beim Dekodieren des JWT-Tokens:', error);
        return {};
    }
};

export const useUserData = () => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState(decodeJWTToken(localStorage.getItem("token")));
    let isLoggedIn = useLoginState()

    useEffect(() => {
        const token = localStorage.getItem("token");
        setUserData(decodeJWTToken(token));

        if (!isLoggedIn) {
            console.log("Navigiere zu Login")
            navigate('/login');
        }
    }, [isLoggedIn]);

    return userData
};



export const useLoginState = () => {
    let navigate = useNavigate()
    const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("token") !== null);

    useEffect(() => {
        let bool = localStorage.getItem("token") !== null
        setIsLoggedIn(bool)
    }, [navigate]);

    return isLoggedIn;
};




// Login-Funktion
export function login(identifier, password) {

    return axios({
        method: "post",
        data: JSON.stringify({ email: identifier, password: password }),
        url: AuthServer + "/login",
        headers: {
            'Content-Type': 'application/json'
        },
    })
        .then((res) => {
            if (!res.data || !res.data.token) {
                console.log("No res datas");
                throw new Error("Ungültige Anmeldedaten.");
            }
            console.log(res.data);
            const { token } = res.data;
            localStorage.setItem("token", token);
        })
        .catch((error) => {
            console.error(error);
            throw new Error("Ungültige Anmeldedaten.");
        });
}


// Logout-Funktion
export function logout() {
    localStorage.removeItem("token");
}



export function CheckAuth({ children }) {
    let isLoggedIn = useLoginState()
    let navigate = useNavigate()
    if (isLoggedIn) {
        return <>{children}</>
    }
    else {
        navigate('/login');
        return <></>
    }
}