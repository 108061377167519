import { useEffect, useState } from 'react';
import CastProvider from 'react-chromecast';
import { Route, Routes, useNavigate } from 'react-router-dom';
import FixedLegalNotice from './components/atoms/texts/FixedLegalNotice';
import BarrierefreiheitPage from './components/screens/BarrierefreiheitPage/BarrierefreiheitPage';
import DebugPage from './components/screens/DebugPage/DebugPage';
import EasySpeachPage from './components/screens/EasySpeachPage/EasySpeachPage';
import HomePage from './components/screens/HomePage';
import ImpressumPage from './components/screens/ImpressumPage/ImpressumPage';
import KontaktPage from './components/screens/KontaktPage/KontaktPage';
import Page404 from './components/screens/Page404/Page404';
import PlayScenarioPage from './components/screens/PlayScenarioPage/PlayScenarioPage';
import ScenarioOverviewPage from './components/screens/ScenarioOverviewPage/ScenarioOverviewPage';
import ServicePage from './components/screens/ServicePage/ServicePage';
import UserAddPage from './components/screens/UserAddPage/UserAddPage';
import UserListPage from './components/screens/UserListPage/UserListPage';
import UserPage from './components/screens/UserPage/UserPage';
import WebViewPage from './components/screens/WebViewPage/WebViewPage';
import WelcomePage from './components/screens/WelcomePage/WelcomePage';
import LoginPage from './components/screens/auth/LoginPage/LoginPage';
import LogoutPage from './components/screens/auth/LogoutPage/LogoutPage';
import NewPasswordPage from './components/screens/auth/NewPasswordPage/NewPasswordPage';
import ResetPasswordPage from './components/screens/auth/ResetPasswordPage/ResetPasswordPage';
import SidebarPageTemplate from './components/templates/SidebarPageTemplate/SidebarPageTemplate';
import { useLoginState } from "./service/Auth";
import './style/colors.scss';
import './style/default.scss';
import './style/fonts.scss';
function App() {
  document.title = 'VR Ausbildung GABC';

  const [IsWebView, setIsWebView] = useState(false)
  let navigate = useNavigate()

  useEffect(() => {
    const isWebview = window.location.pathname === '/webview';
    setIsWebView(isWebview)
  }, [navigate])

  let isLoggedIn = useLoginState()
  let authRoutes = isLoggedIn &&
    <>
      <Route path="/scenario/:id" element={<ScenarioOverviewPage />} />
      <Route path="/home/:education/:scenarioGroup" element={<PlayScenarioPage />} />
      <Route path="/home/:education/" element={<PlayScenarioPage />} />
      <Route path="/home/" element={<WelcomePage />} />
      <Route path="/service/" element={<ServicePage />} />

      <Route path="/feuerwehr/erstellen" element={<UserAddPage />} />
      <Route path="/feuerwehr/:id" element={<UserPage />} />
      <Route path="/feuerwehr" element={<UserListPage />} />
    </>


  let routes = <Routes>
    {/* Öffentliche Seiten */}
    <Route path="/" element={<HomePage />} />
    <Route path="/kontakt" element={<KontaktPage />} />

    {/* Rechtliche Seiten */}
    <Route path="/impressum" element={<ImpressumPage />} />
    <Route path="/barrierefreiheit" element={<BarrierefreiheitPage />} />
    <Route path="/datenschutz" element={<ImpressumPage />} />
    <Route path="/leichte-sprache" element={<EasySpeachPage />} />

    {/* Auth */}
    <Route path="/login" element={<LoginPage />} />
    <Route path="/neues-passwort" element={<NewPasswordPage />} />
    <Route path="/passwort-vergessen" element={<ResetPasswordPage />} />
    <Route path="/logout" element={<LogoutPage />} />


    {/* Interne Seiten //todo: AUTH */}
    {authRoutes}

    <Route path="/debug/" element={<DebugPage />} />
    <Route path="/*" element={<Page404 />} />

  </Routes>

  return (
    <CastProvider>
      <div className="App">
        <Routes>
          <Route path="/webview" element={<WebViewPage />} />
        </Routes>


        {IsWebView ? routes :
          <SidebarPageTemplate>
            {routes}
          </SidebarPageTemplate>
        }
      </div>

      <FixedLegalNotice />
    </CastProvider>
  );
}
export default App;