import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { showUserManager } from "../../../service/Permissions"
import { CreateDepartment } from "../../../service/Strapi"
import ErrorText from "../../molecules/errors/ErrorText/ErrorText"
import UserPageTemplate from "../../templates/UserPageTemplate/UserPageTemplate"
import "./UserAddPage.scss"

function UserAddPage({ params }) {

    let navigate = useNavigate()
    const [Data, setData] = useState({
        parentDepartment: {},//eigene feuerwehr Daten

    })


    function handleChange(e) {
        let { key, value } = e

        setData({ ...Data, [key]: value })
        console.log(key, value)
        console.log(Data)
    }

    function localCheck() {
        return true
    }

    function handleSave() {
        if (localCheck()) {
            CreateDepartment(Data)
                .then(res => {

                    let id = res?.data?.id
                    navigate(`/feuerwehr/${id}`);


                    console.log(res)
                })
        }
    }

    if (showUserManager()) {
        return (
            <div>
                <UserPageTemplate
                    {...Data}
                    title="Feuerwehr erstellen"
                    onChange={handleChange}
                    hideParentSystem
                    hidePermissionSystem
                />
                <p>Mit dem Klick auf "Einladung senden" wird die Feuerwehr angelegt und eine Email zum erstellen des Passwortes an die Feuerwehr gesendet. Sie können im nächsten Schritt die Berechtigungen anpassen.</p>
                <button onClick={handleSave}>Einladung senden</button>
            </div>)
    } else {
        return <ErrorText error="Keine Berechtigung" />
    }
}
export default UserAddPage