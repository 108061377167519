import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { useScenarios } from "../../../service/Scenario"
import { EducationPersonBox } from "../../molecules/Person/PersonBox"
import ScenarioGroupFilter from "../../molecules/scenarios/ScenarioGroupFilter"
import ScenarioList from "../../molecules/scenarios/ScenarioList"
import TextPageTemplate from "../../templates/TextPageTemplate/TextPageTemplate"
import "./PlayScenarioPage.scss"

function PlayScenarioPage({ }) {
    let { education } = useParams()
    const [Filter, setFilter] = useState()

    let { data, loading, error } = useScenarios({
        educationUri: education,
        // scenarioGroupUri: "atomar"
    })

    if (loading) return <TextPageTemplate title={"Ausbildung lädt..."} className="PlayScenarioPage"></TextPageTemplate>
    if (error) return <p>Error: {error}</p>
    if (data) {
        let scenarios = data || []
        let brakePoint = "md"//#endregion
        let educationObject = scenarios[0]?.attributes?.education?.data?.attributes || { title: "Nicht freigegeben" }

        if (Filter) {
            scenarios = scenarios.filter(i => i?.attributes?.scenario_group?.data?.attributes?.uri == Filter)
        }

        return (
            <TextPageTemplate title={educationObject.title} className="PlayScenarioPage">
                <div className="row p-0">
                    <div className={`col-12 col-${brakePoint}-8 p-0`}>
                        <ScenarioGroupFilter activeFilter={Filter} onFilter={(f) => setFilter(f)} />
                        <div className="">
                            <ScenarioList scenarios={scenarios} />
                        </div>
                    </div>
                    <div className={`col-12 col-${brakePoint}-3 p-0 d-none d-${brakePoint}-flex`}>
                        <div className="px-3">
                            <EducationPersonBox educationUri={education} />
                        </div>
                    </div>
                </div>
            </TextPageTemplate>
        )
    }
}
export default PlayScenarioPage
