import React from "react"
import "./ErrorText.scss"

function ErrorText({ children = "Fehler beim laden.", errorCode = "Versuchen Sie es später erneut.", onClick, error, className = "" }) {
    return (
        <div className={"ErrorText " + className} onClick={onClick}>
            <i className="fa-solid fa-triangle-exclamation"></i>

            <span className="title">
                {children}
            </span>
            <span className="errorCode">
                {errorCode}
            </span>
        </div>
    )
}
export default ErrorText