import React from "react"
import useStrapiData, { GetImageSource } from "../../../service/Strapi"
import "./PersonBox.scss"

export function EducationPersonBox({ educationUri }) {
    let endpoint = "/people?filter[educations][uri][$eq]=" + educationUri + "&populate[0]=image"
    let { data, loading, error } = useStrapiData(endpoint)
    if (loading) return <p>Loading...</p>
    if (error) return <p>Error: {error}</p>
    if (!data[0]) return null // <p>Dieser Ausbildung wurde noch kein Ansprechpartner zugewiesen.</p>
    return <PersonBox {...data[0]?.attributes} />
}

export function PersonBox({ fullName, email, image, position }) {
    let imgSrc = GetImageSource(image)
    let mailto = email ? `mailto:${email}` : false
    return (
        <div className="PersonBox">
            <div className="image" style={{ backgroundImage: `url(${imgSrc})` }} />
            <div className="pt-2">
                <h2 className="mb-0">{fullName}</h2>
                <p>{position}</p>
                <div>
                    <p><a href={mailto} target="_blank" >{email}</a></p>
                </div>
            </div>
        </div>
    )
}