import React from "react"
import { useApi } from "../../../../service/API"
import ErrorText from "../ErrorText/ErrorText"
import "./NoServerConnectionBox.scss"

function NoServerConnectionBox({
    title = "Keine Serververbindung!",
    errorCode = "Bitte prüfen Sie Ihre Internetverbindung und laden Sie die Seite in wenigen Minuten erneut."
}) {
    let { data, loading, error, } = useApi("")

    // return <p>fhhfu</p>
    if (loading) return null
    if (error) return <ErrorText
        className="mb-3"
        errorCode={errorCode}>
        {title}
    </ErrorText>
    if (data) return null
}
export default NoServerConnectionBox