

export const ServerAddress = process.env.REACT_APP_STRAPI_URL || "https://hlfs-datahub.hessenapp.de"
export const ApiServer = process.env.REACT_APP_API_SERVER || "https://api.hlfs-vr.de"
export const ScenarioCmsServer = "https://cdn.hlfs-vr.de/scenarios/"
export const AuthServer = ApiServer + "/auth"

export const StrapiAddress = ServerAddress + "/api"
export const StrapiLoginUrl = ServerAddress + "/admin"

export const ApiKey = process.env.REACT_APP_STRAPI_API_KEY || "5a6c6eca3dfae41fc8f8bc9d13a5df21d2adb426fab80a03747787d6e2d685662798edf5fa131a536f7d942069475c90c64da4c2ba241c9e4cd0ea4b8f125b52e48edbdd3ec9c47f023bd8f0880889078e97a9c54ddea2180ce5ba2ead660cb32626bf094bfff6040a5919f779b92fa57f5383677fb165aa47b72e068ee7ceb1"

export const JWT = localStorage.getItem("token")
export const API_HEADERS = {
    'Authorization': `Bearer ${JWT}`,
    "Content-Type": "application/json"
}

export const IFRAME_PERMISSIONS = "fullscreen; accelerometer; gyroscope; magnetometer; vr; xr; xr-spatial-tracking; autoplay; camera; microphone"



export const STRAPI_EDIT_FAQ = StrapiLoginUrl + "/content-manager/collection-types/api::faq-item.faq-item"
export const STRAPI_EDIT_HOMEPAGE = StrapiLoginUrl + "/content-manager/single-types/api::homepage.homepage"


// export const ServerAddress = "https://backend.hlfs-vr.de"
// export const ApiServer = "https://api.hlfs-vr.de"
// export const ScenarioCmsServer = "https://cdn.hlfs-vr.de"
// export const AuthServer = ApiServer + "/auth"

// export const StrapiAddress = ServerAddress + "/api"
// export const StrapiLoginUrl = ServerAddress + "/admin"

// export const ApiKey = "6c354d689907c4e8145ab233fcba21e6be5ca3b51a0bedd67d62fd4fae6ead8cfec394ef9d1c623f4af0811ae2acca341a39b53d57e2a406927babbfbf566bf76a6c3399d8573584dd5f4b7eb3a49fe0c2d322e329fbdb9591286865fd8e7a56a6146b4f1a14617e6f5337702003eb7833f15df2bee53e9f891245e7659c899b"

// export const JWT = localStorage.getItem("token")

// export const IFRAME_PERMISSIONS = "fullscreen; accelerometer; gyroscope; magnetometer; vr; xr; xr-spatial-tracking; autoplay; camera; microphone"