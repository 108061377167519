import React, { useState } from "react"
import Checkbox from "../../../atoms/inputs/Checkbox/Checkbox"
import ScenarioPermission from "../ScenarioPermission/ScenarioPermission"
import "./EducationPermissions.scss"

function EducationPermissions({ title, scenarios = [], targetDepartmentId }) {

    const [IsOpen, setIsOpen] = useState(true)


    let selectionText = "Keins Ausgewählt"
    let isActive = false

    //Wenn Alle Szenarien A
    if (scenarios.map(i => i.attributes.isActive).every(i => i === true)) {
        selectionText = "Alle Ausgewählt"
        isActive = true
    } else if (scenarios.map(i => i.attributes.isActive).every(i => i === false)) {
        selectionText = "Keins Ausgewählt"
        isActive = true
    } else {
        selectionText = "Teilweise Ausgewählt"
    }

    function toggleChildren() {
        setIsOpen(!IsOpen)
    }


    return (
        <div className="EducationPermissions mb-4">
            <div className="d-flex flex-row justify-content-between">
                <div className="titleBox" onClick={toggleChildren}>
                    <span className="lable">{title}</span>
                    {IsOpen ? <i className="fa-solid fa-chevron-up"></i> : <i className="fa-solid fa-chevron-down"></i>}
                </div>

                <div className="d-flex flex-row">
                    <span className="px-2">
                        {selectionText}
                    </span>
                    <Checkbox isActive={isActive} />
                </div>
            </div>

            {
                IsOpen &&
                scenarios?.map((ii, k) => <div>
                    <ScenarioPermission {...ii} key={k} targetDepartmentId={targetDepartmentId} />
                </div>)
            }
        </div>
    )
}
export default EducationPermissions