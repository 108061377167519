import React, { useEffect } from "react"
import { logout } from "../../../../service/Auth"
import "./LogoutPage.scss"

function LogoutPage({ }) {

    useEffect(() => {
        logout()
        window.location.href = "/login"
    }, [])


    return (
        <div className="LogoutPage">
            {/* <Logo /> */}
            <div className="pt-5 mt-5">
                <h1>Abmeldung wird durchgeführt...</h1>
                <p>Einen kleinen Moment bitte, wir leiten Sie weiter...</p>
            </div>
        </div>
    )
}
export default LogoutPage