import React from "react"
import { Link } from "react-router-dom"
import { useLoginState } from "../../../service/Auth"
import TextPageTemplate from "../../templates/TextPageTemplate/TextPageTemplate"
import "./Page404.scss"

function Page404({ }) {
    let isLoggedIn = useLoginState()



    return (
        <TextPageTemplate showHeader title="Seite nicht verfügbar." className="Page404">
            <div className="mt-5 mb-5">

                <h2>Mögliche Ursachen:</h2>

                <div className="mt-2 mb-5">
                    <ul>
                        <li>Sie verfügen nicht über die erforderliche Berechtigung.</li>
                        <li>Möglicherweise wurde die Seite entfernt.</li>
                        <li>Es gibt möglicherweise ein Problem mit dem von Ihnen angeklickten Link.</li>
                    </ul>
                </div>

                <p><small>Fehlercode: 404/403</small></p>


                {!isLoggedIn && <button><Link to="/login">Anmelden</Link></button>}
                <button><Link to="/">Zurück zur Startseite</Link></button>


            </div>
        </TextPageTemplate>
    )
}
export default Page404