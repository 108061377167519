import React from "react"
import ErrorText from "../ErrorText/ErrorText"
import "./ErrorTextNetwork.scss"

function ErrorTextNetwork({ children = "Fehler beim laden.", errorCode = "Versuchen Sie es später erneut.", onClick, error }) {
    console.warn(error)
    return (
        <ErrorText errorCode={"Code: 500"} error={error}>Serververbindung fehlgeschlagen.</ErrorText>
    )
}
export default ErrorTextNetwork