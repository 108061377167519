import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_HEADERS, ApiServer } from "../../../../const/Server";
import { GetImageSource } from "../../../../service/Strapi";
import Checkbox from "../../../atoms/inputs/Checkbox/Checkbox";
import DetailTags from "../../../atoms/texts/DetailTags";
import "./ScenarioPermission.scss";

function ScenarioPermission({ attributes, id, onTogglePermission = () => { }, targetDepartmentId }) {
    const { title, key, code, level, image, scenario_group } = attributes;
    const [IsSelected, setIsSelected] = useState(attributes?.isActive);
    const [loading, setLoading] = useState(false);
    const [Error, setError] = useState(false)

    let imgSrc = GetImageSource(image)

    useEffect(() => {
        setIsSelected(attributes?.isActive);
    }, [attributes]);

    async function handleTogglePermission() {
        setLoading(true);
        setError(false)
        try {
            let endpoint = IsSelected ? "/removeScenario" : "/addScenario"
            await axios({
                url: ApiServer + "/departments" + endpoint,
                method: "POST",
                headers: API_HEADERS,
                data: {
                    isSelected: !IsSelected,
                    targetDepartmentId: targetDepartmentId,
                    scenarioId: id,
                },
            });
            //user ID?

            // Hier aktualisierst du den lokalen Zustand nach der erfolgreichen API-Antwort
            setIsSelected(!IsSelected);
            onTogglePermission(key, !IsSelected);
        } catch (error) {
            setError(true)
            console.error("Fehler bei der API-Anfrage: ", error);
        } finally {
            setLoading(false);
        }
    }

    let icon = scenario_group?.data?.attributes?.icon

    let text = "Nicht berechtigt"
    if (IsSelected) text = "Berechtigt"
    if (loading) {
        if (IsSelected) text = "Entfernt..."
        if (!IsSelected) text = "Fügt hinzu..."
    }
    if (Error) text = "Fehler: F-P-001"

    return (
        <div className={"mb-1 ScenarioPermission " + (IsSelected && " isActive ") + (Error && " Error ")} onClick={handleTogglePermission}>
            <div style={{ backgroundImage: `url(${imgSrc})` }} className="img" />

            <div className=" p-1 content">
                <DetailTags level={level} code={code} icon={icon} />
                <h3>{title} {IsSelected}</h3>
            </div>

            <div className="d-flex">

                <div className="mr-4 px-2">
                    <p className="mb-0">{text}</p>
                </div>

                <Checkbox isActive={IsSelected} loading={loading} error={Error} />
            </div>
        </div>
    );
}

export default ScenarioPermission;
