import axios from "axios"
import { API_HEADERS, ApiServer } from "../const/Server"

export async function DeleteDepartment(id) {
    if (window.confirm("Möchten Sie die Feuerwehr wirklich unwiderruflich löschen?")) {

        let endpoint = ApiServer + "/departments"
        let config = {
            method: 'DELETE',
            url: endpoint,
            headers: API_HEADERS,
            data: { targetDepartmentId: id }
        }

        console.log(config)
        axios(config)
            .then((res) => {
                alert("✅ Feuerwehr gelöscht!")
                return res
            })
            .catch((err) => {
                alert("🚨 Fehler beim Löschen der Feuerwehr!")
            })
    }
}

export async function EditDepartmentUsername(id, username) {
    let endpoint = ApiServer + "/departments/change/username"
    let config = {
        method: 'POST',
        url: endpoint,
        headers: API_HEADERS,
        data: { targetDepartmentId: id, username: username }
    }

    console.log("EditDepartmentUsername conf: ", config)
    axios(config)
        .then((res) => {
            // alert("✅ Feuerwehr gelöscht!")
            console.log("Feuerwehr umbenannt!")
            return res
        })
        .catch((err) => {
            alert("🚨 Fehler beim umbenennen der Feuerwehr!")
        })
}

export async function EditDepartmentEmail(id, email) {
    let endpoint = ApiServer + "/departments/change/email"
    let config = {
        method: 'POST',
        url: endpoint,
        headers: API_HEADERS,
        data: { targetDepartmentId: id, email: email }
    }
    console.log("EditDepartmentEmail conf: ", config)

    axios(config)
        .then((res) => {
            // alert("✅ Feuerwehr gelöscht!")
            console.log("Feuerwehr email geändert!")
            return res
        })
        .catch((err) => {
            alert("🚨 Fehler beim ändern der Feuerwehr email!")
        })
}


export async function EditDepartmentCanCreateUsers(id, value) {
    let endpoint = ApiServer + "/departments/change/canCreateUsers"
    let config = {
        method: 'POST',
        url: endpoint,
        headers: API_HEADERS,
        data: { targetDepartmentId: id, value: value }
    }

    axios(config)
        .then((res) => {
            // alert("✅ Feuerwehr gelöscht!")
            return res
        })
        .catch((err) => {
            alert("🚨 Fehler beim ändern der Berechtigung.")
        })
}