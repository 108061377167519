import React from "react"
import { isAdmin } from "../../../service/Permissions"
import { STRAPI_MAIN } from "../../../service/Strapi"
import "./EditInStrapi.scss"

function EditInStrapi({ id, type = "department", url = "" }) {
    if (!isAdmin()) return null

    if (!url) {
        url = `${STRAPI_MAIN}/admin/content-manager/collection-types/api::${type}.${type}/${id}`
    }

    return (
        <a href={url} target="_blank">
            <button className="EditInStrapi" >
                <i className="fa-solid fa-edit"></i> Bearbeiten in Strapi
            </button>
        </a>
    )
}
export default EditInStrapi