import React from "react"
import TextPageTemplate from "../../templates/TextPageTemplate/TextPageTemplate"
import "./BarrierefreiheitPage.scss"

function BarrierefreiheitPage({ }) {
    return (
        <TextPageTemplate showHeader title="Erklärung zur Barrierefreiheit" className="BarrierefreiheitPage">
            <h2>Herausgegeben von der Hessischen Landesfeuerwehrschule</h2>


            <p>
                Diese Erklärung zur digitalen Barrierefreiheit gilt für die unter{' '}
                <a href="https://hlfs-vr.de">https://hlfs-vr.de</a> veröffentlichten Website der Hessischen Landesfeuerwehschule.
                Als öffentliche Stelle im Sinne der Richtlinie (EU) 2016/2102 sind wir bemüht, unsere Websites und mobilen Anwendungen
                im Einklang mit den Bestimmungen des Hessisches Behinderten-Gleichstellungsgesetz (HessBGG) sowie der Hessischen Verordnung
                über barrierefreie Informationstechnik (HVBIT) zur Umsetzung der Richtlinie (EU) 2016/2102 barrierefrei zugänglich zu machen.
            </p>
            <p>
                Stand der Vereinbarkeit mit den Anforderungen<br />
                Die Anforderungen der Barrierefreiheit ergeben sich aus §§ 3 Absätze 1 bis 4 und 4 der HVBIT, die auf der Grundlage von §14 des HessBGG erlassen wurde.
                Die Überprüfung der Einhaltung der Anforderungen beruht auf einer im Juni 2023 durchgeführten Selbstbewertung.
            </p>
            <h2>Nicht barrierefreie Inhalte</h2>
            <p>
                Aufgrund der Überprüfung ist die Website mit den zuvor genannten Anforderungen wegen der folgenden Ausnahmen teilweise vereinbar.
            </p>
            <p>
                <strong>Unvereinbarkeit mit der HVBIT</strong>
            </p>
            <p>
                <strong>Barriere 1:</strong><br />
                Beschreibung: Die Kontraste von Text/Icon- zu Hintergrundfarbe erfüllen im geschützten Bereich, sowie den 360°-Videos teilweise nicht die Anforderungen an die Barrierefreiheit.<br />
                Begründung: Beschreibungstexte über Grafiken besitzen je nach Hintergrundgrafik geringen Kontrast. In den 360°-Videos wird sich bemüht hohe Kontraste für Icons zu verwenden, dies kann aber nicht garantiert werden.<br />
                Barrierefreie Alternative: Derzeit ist keine barrierefreie Alternative vorhanden.
            </p>
            <p>
                <strong>Barriere 2:</strong><br />
                Beschreibung: Die korrekte Lesereihenfolge der Website-Inhalte kann im geschützten Bereich nicht immer korrekt durch Software bestimmt werden.<br />
                Barrierefreie Alternative: Derzeit ist keine barrierefreie Alternative vorhanden.
            </p>
            <h2>Unverhältnismäßige Belastung</h2>
            <p>
                <strong>Barriere 1:</strong><br />
                Beschreibung: Die eingebundenen 360°-Videos enthalten keine Audiodeskription und keine Untertitel.<br />
                Begründung: Wir sind der Ansicht, dass eine Behebung eine unverhältnismäßige Belastung im Sinne der Barrierefreiheitsbestimmungen darstellen würde.
                Zudem müssen Feuerwehrkräfte nach §6 der DGUV-Vorschrift 49 eine körperliche und geistige Eignung aufweisen. Im geschützten Bereich der Website geht es hauptsächlich um die Abarbeitung von Lageerkundungen mittels 360°-Videos.
                Dabei wird sich hauptsächlich an Führungskräfte gerichtet. Auf Basis der vorher genannten Vorschrift bewerten wir es daher als unwahrscheinlich, dass der geschützte Bereich der Website von einer gehörlosen oder sehbehinderten Person verwendet wird.<br />
                Barrierefreie Alternative: Derzeit ist keine barrierefreie Alternative vorhanden.
            </p>
            <p>
                <strong>Kein Anwendungsfall</strong><br />
                Es liegen keine Inhalte vor, die nicht in den Anwendungsbereich des §3 Absatz 1 HVBIT fallen.
            </p>
            <p>
                Datum der Erstellung der Erklärung zur Barrierefreiheit<br />
                Diese Erklärung wurde am 01.06.2023 erstellt.
            </p>
            <h2>Ansprechpartner bei Anmerkungen und Fragen zur digitalen Barrierefreiheit</h2>
            <p>
                Sie möchten uns bestehende Barrieren mitteilen oder Informationen zur Umsetzung der Barrierefreiheit erfragen?
                Für Ihr Feedback sowie alle weiteren Informationen sprechen Sie unsere verantwortlichen Kontaktpersonen an.
            </p>
            <p>
                Ansprechperson:<br />
                Stabstelle 1 / Maren Apel<br />
                Telefon +49 (0) 561 / 31 002-0<br />
                E-Mail: <a href="mailto:poststelle@hlfs.hessen.de">poststelle@hlfs.hessen.de</a>
            </p>
            <h2>Durchsetzungs- und Überwachungsstelle Barrierefreie Informationstechnik</h2>
            <p>
                Wenn auch nach Ihrem Feedback an den oben genannten Kontakt keine zufriedenstellende Lösung gefunden wurde,
                können Sie die Durchsetzungs- und Überwachungsstelle Barrierefreie Informationstechnik einschalten.
                Sie haben nach Ablauf einer Frist von sechs Wochen das Recht sich direkt an die Durchsetzungs- und Überwachungsstelle zu wenden.
                Unter Einbeziehung aller Beteiligten versucht die Ombudsstelle, die Umstände der fehlenden Barrierefreiheit zu ermitteln,
                damit der Träger diese beheben kann.
            </p>
            <p>
                <strong>Durchsetzungs- und Überwachungsstelle Barrierefreie Informationstechnik</strong><br />
                Hessisches Ministerium für Soziales und Integration<br />
                Sitz: Regierungspräsidium Gießen<br />
                Prof. Dr. Erdmuthe Meyer zu Bexten<br />
                Landesbeauftragte für barrierefreie IT<br />
                Leiterin der Durchsetzungs- und Überwachungsstelle<br />
                Landgraf-Philipp-Platz 1-7<br />
                35390 Gießen<br />
                Telefon: +49 641 303 - 2901<br />
                E-Mail: <a href="mailto:Durchsetzungsstelle-LBIT@rpgi.hessen.de">Durchsetzungsstelle-LBIT@rpgi.hessen.de</a>
            </p>


        </TextPageTemplate>
    )
}
export default BarrierefreiheitPage