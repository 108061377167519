import React from "react"
import ScenarioItem from "./ScenarioItem"
import "./ScenarioList.scss"

function ScenarioList({ scenarios = [] }) {

    if (scenarios.length == 0) return <div className="mt-4">
        <h2>Keine Szenarien verfügbar</h2>
        <p>Scheinbar wurden Ihnen noch keine Szenarien in dieser Ausbildung freigegeben. Kontaktieren Sie ggf. Ihren Ansprechpartner.</p>
    </div>

    return (
        <div className="ScenarioList">
            {scenarios.map((i, k) => <ScenarioItem key={k} {...i} />)}
        </div>
    )
}
export default ScenarioList