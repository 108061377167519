
import React from "react"
import { useParams } from "react-router-dom"
import { useApi } from "../../../service/API"
import DefaultLoader from "../../atoms/DefaultLoader/DefaultLoader"
import ErrorText from "../../molecules/errors/ErrorText/ErrorText"
import ScenarioOverviewPageTemplate from "../../templates/ScenarioOverviewPageTemplate/ScenarioOverviewPageTemplate"
import "./ScenarioOverviewPage.scss"

function ScenarioOverviewPage({ params }) {
    let { id } = useParams()
    let endpoint = "/scenarios/" + id
    let { data, loading, error } = useApi(endpoint)
    if (loading) return <DefaultLoader />
    if (error) return <ErrorText error={error} />
    if (!data) return <ErrorText error="Keine Daten gefunden" />
    if (data) return <ScenarioOverviewPageTemplate {...data} />
}
export default ScenarioOverviewPage