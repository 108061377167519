import { JWT } from "../const/Server";
import { decodeJWTToken } from "./Auth";


// Funktionen
export function canCreateUser() {
    let data = decodeJWTToken(JWT)
    return data.isAdmin || data.canCreateUsers
}

export function showUserManager() {
    let data = decodeJWTToken(JWT)
    return data.isAdmin || data.canCreateUsers || data.childDepartments?.length > 0
}

export function isAdmin() {
    let data = decodeJWTToken(JWT)
    return data.isAdmin || data.attributes
}