import React from "react"
import TextPageTemplate from "../../templates/TextPageTemplate/TextPageTemplate"
import "./KontaktPage.scss"

function KontaktPage({ }) {
    return (
        <TextPageTemplate showHeader className="KontaktPage" title="Kontakt">

            <div className="mb-5">


                <div className="row pb-5 align-items-center">
                    <div className="col-12 p-0 pe-5">
                        {/* <h2>Ihr Kontakt in die Redaktion</h2> */}
                        <p className="welcome">Sie haben Fragen an uns, möchten mehr über die HLFS erfahren oder uns einfach nur Ihre Meinung sagen?
                            Kontaktieren Sie uns, wir freuen uns über Feedback sind gerne für Sie da.</p>

                        <div className="d-flex flex-row p-3 ps-0">
                            <a href="mailto:ausbildung-gabc@hlfs.hessen.de" title="ausbildung-gabc@hlfs.hessen.de" target={"_blank"}><button className="main"> <i className="fa-solid fa-envelope"></i> Mail schreiben</button></a><br />
                            <a href="tel:49561310020" title="(+49) 561-31002-0"><button> <i className="fa-solid fa-phone"></i> Jetzt anrufen</button></a><br />
                        </div>
                    </div>


                </div>

                <hr />

                <div className="pb-5">
                    <h2 style={{ fontSize: 28, }}>Anschrift</h2>
                    <p>
                        <b>Hessische Landesfeuerwehrschule</b>
                        <br />
                        Heinrich-Schütz-Allee 62<br />
                        34134 Kassel<br />
                        <br />
                        T: <a href="tel:49561310020">(+49) 561-31002-0</a><br />
                        E: <a href="mailto:ausbildung-gabc@hlfs.hessen.de" target={"_blank"}>ausbildung-gabc@hlfs.hessen.de</a><br />
                        F: 0561-31002-102
                    </p>
                </div >
            </div >
        </TextPageTemplate>
    )
}
export default KontaktPage