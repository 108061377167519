import { useMemo, useState } from "react";
import { ApiServer } from "../const/Server";
import { alenaxios } from "./Alenaxios";

export const useApi = (uri, params) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    console.log("useApi ", uri, params) //leer?

    useMemo(() => {
        const fetchData = async () => {
            try {
                const response = await alenaxios(ApiServer + uri,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                        params: params,
                    });
                setData(response?.data?.data || response?.data || []);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [uri, JSON.stringify(params)]);

    return { data, loading, error };
};
