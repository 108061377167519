import React from "react"
import { Link } from "react-router-dom"
import "./FixedLegalNotice.scss"

function FixedLegalNotice({ }) {
    return (
        <div className="FixedLegalNotice d-none d-lg-block">
            <Link to="/impressum">Impressum</Link>
            <Link to="/datenschutz">Datenschutzerklärung</Link>
        </div>
    )
}
export default FixedLegalNotice