import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { ApiServer } from "../../../../const/Server";
import { logout } from "../../../../service/Auth";
import { isTokenValid } from "../../../../utils/JwtHelper";
import NoServerConnectionBox from "../../../molecules/errors/NoServerConnectionBox/NoServerConnectionBox";
import Page404 from "../../Page404/Page404";
import "./NewPasswordPage.scss";

function NewPasswordPage() {
    const navigate = useNavigate()
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState(null);
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    if (!isTokenValid(token)) return (<Page404 />);

    const isPasswordValid = () => {
        // Das Passwort muss mindestens 8 Zeichen lang sein
        if (password.length < 8) {
            return false;
        }

        return true;
    };

    const handleSubmit = async () => {
        if (!isPasswordValid()) {
            setError("Das Passwort erfüllt nicht die Sicherheitsanforderungen der HLFS.");
            return;
        }
        if (password !== confirmPassword) {
            setError("Die eingegebenen Passwörter stimmen nicht überein.");
            return;
        }
        try {
            const response = await axios.post(ApiServer + "/auth/newPassword", {
                token,
                password,
            });
            // Handle the response as needed
            console.log("Passwort erfolgreich geändert: ", response.data);
            logout()
            navigate("/login")
        } catch (err) {
            setError("Fehler beim Ändern des Passworts. Bitte versuchen Sie es erneut.");
            // Handle the error appropriately
        }
    };

    return (
        <div className="NewPasswordPage">
            <NoServerConnectionBox title="Link abgelaufen" text="Der Link zum ändern Ihres Passworts ist leider abgelaufen." />
            <h1>Neues Passwort</h1>
            {error && <div className="error-message">{error}</div>}
            <input
                autoComplete="new-password"
                type="password"
                placeholder="Neues Passwort eingeben"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <input
                autoComplete="new-password"
                type="password"
                placeholder="Passwort bestätigen"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <button onClick={handleSubmit}>Passwort ändern</button>
        </div>
    );
}

export default NewPasswordPage;
