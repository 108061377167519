import React from "react"
import { ApiKey, ApiServer, JWT, ScenarioCmsServer, ServerAddress } from "../../../const/Server"
import { decodeJWTToken } from "../../../service/Auth"
import { isAdmin } from "../../../service/Permissions"

function DebugPage({ }) {

    let t = JWT

    let data = decodeJWTToken(t) || {}

    return (
        <div className="DebugPage p-2">
            <h1>Entwickler Infos</h1>

            <hr />
            <div>
                <h2>Account</h2>
                <div className="p-2">
                    <h3>Name:</h3>
                    <p>{data.username}</p>

                    <h3>Admin:</h3>
                    <p>{isAdmin() ? "Ja" : "Nein"}</p>

                    <h3>Parent ID:</h3>
                    <p>{data.parentDepartment}</p>

                    <h3>Login läuft ab:</h3>
                    {t && <p>{new Date(data.exp * 1000).toISOString()}</p>}
                </div>
            </div>


            <hr />
            <div>

                <h2>JWT</h2>
                <div className="p-2">
                    <h3>Token:</h3>
                    {t}
                </div>

                <div className="p-2">
                    <h3>Decoded</h3>
                    {JSON.stringify(data, null, 2)}
                </div>
            </div>

            <hr />
            <h2>ENV</h2>
            <div className="p-2">
                <h3>process.env.REACT_APP_STRAPI_URL: {process.env.REACT_APP_STRAPI_URL || "null"}</h3>
                <h3>process.env.REACT_APP_API_SERVER: {process.env.REACT_APP_API_SERVER || "null"}</h3>
                <h3>process.env.REACT_APP_STRAPI_API_KEY: {process.env.REACT_APP_STRAPI_API_KEY || "null"}</h3>
            </div>

            <hr />
            <h2>Server</h2>
            <div className="p-2">
                <h3>ServerAddress: {ServerAddress}</h3>
                <h3>ApiServer: {ApiServer}</h3>
                <h3>ScenarioCmsServer: {ScenarioCmsServer}</h3>
                <h3>ApiKey: {ApiKey}</h3>


            </div>
        </div>
    )
}
export default DebugPage