import React from "react"
import TextPageTemplate from "../../templates/TextPageTemplate/TextPageTemplate"
import "./ImpressumPage.scss"

function ImpressumPage({ }) {
    return (
        <TextPageTemplate showHeader title="Impressum" >


            <h2>Land Hessen</h2>
            <p>Hessische Landesfeuerwehrschule</p>
            <p>Heinrich-Schütz-Allee 62</p>
            <p>34134 Kassel</p>
            <p>
                E-Mail:{" "}
                <a href="mailto:poststelle@hlfs.hessen.de" className="cm_anchor">
                    poststelle@hlfs.hessen.de
                </a>
            </p>
            <p>Tel.: 0561-31002-0</p>
            <p>Fax: 0561-31002-102</p>
            <p>
                Das Land Hessen ist eine Körperschaft des öffentlichen Rechts.
                Vertretungsberechtigt ist der hessische Ministerpräsident.
            </p>
            <h2>Verantwortlich für den Inhalt i. S. d. § 55 Absatz 2 RStV:</h2>
            <p>Direktor der Schule</p>
            <p>Erwin Baumann</p>
            <p>Heinrich-Schütz-Allee 62</p>
            <p>34134 Kassel</p>
            <h2>Ansprechpartner:</h2>
            <p>Abteilung 3 (GABC und VB), Fachgruppe 31 (GABC)</p>
            <h2>Urheberrecht</h2>
            <p>
                Die Gestaltung der Internetseiten sowie die inhaltlichen Beiträge sind
                urheberrechtlich geschützt. Dies gilt insbesondere für Texte, Bilder,
                Grafiken, Ton-, Video- oder Animationsdateien einschließlich deren Anordnung
                auf den Internetseiten. Veränderungen dürfen hieran nicht vorgenommen
                werden.
            </p>
            <p>
                Eine Vervielfältigung oder Verwendung von Inhalten dieser Internetseiten in
                anderen elektronischen oder gedruckten Publikationen oder deren
                Veröffentlichung (auch im Internet) ist nur nach vorheriger Zustimmung der
                Redaktionsleitung gestattet.
            </p>
            <h2>Ausnahmen:</h2>
            <p>
                1. Einzelne Vervielfältigungen durch eine natürliche Person zum privaten
                Gebrauch sind im Rahmen des § 53 Urheberrechtgesetzes zulässig.
            </p>
            <p>
                2. Zum Download angebotene Informationen (Broschüren, Formulare, Merkblätter
                und ähnliches) dürfen vervielfältigt und in körperlicher Form verbreitet
                werden.
            </p>
            <p>Insbesondere dürfen die zum Download angebotenen Informationen</p>
            <p>
                • auf einer Festplatte oder einem anderen Speichermedium des Nutzers
                gespeichert werden,
            </p>
            <p>• ausgedruckt werden.</p>
            <p>
                Das öffentliche Zugänglichmachen für den interaktiven Abruf ist nicht
                gestattet.
            </p>
            <p>
                Insbesondere dürfen die zum Download angebotenen Informationen nicht auf
                einer Website öffentlich bereitgehalten werden oder anders im Internet zum
                Abruf zur Verfügung gestellt werden.
            </p>
            <p>
                Das Setzen eines Hyperlinks auf die zum Download angebotenen Informationen
                ist nicht zulässig.
            </p>
            <p>
                3. Die Verbreitung und das öffentliche Zugänglichmachen von
                Pressemitteilungen und Reden ist mit Quellenangaben und Datum (der
                Pressemitteilung bzw. der Rede) gestattet.
            </p>
            <h2>Rechte Dritter</h2>
            <p>
                An Bildern, Grafiken, Texten, Marken oder sonstigen Werken und Leistungen
                können ganz oder teilweise Rechte Dritter bestehen.
            </p>
            <h2>Haftungsausschluss</h2>
            <p>
                Die Nachrichten auf dieser Internetseite wurden mit der nach den Umständen
                gebotenen Sorgfalt auf Inhalt, Herkunft und Wahrheit geprüft. Es wird keine
                Gewähr für die Vollständigkeit, Richtigkeit oder Aktualität sowie die
                jederzeitige Verfügbarkeit der bereitgestellten Informationen übernommen.
                Eine Haftung für Schäden, die aus der Nutzung oder Nichtnutzung der auf
                dieser Internetseite angebotenen Informationen oder das Aufrufen oder
                Herunterladen von Daten oder die Installation oder Nutzung von
                heruntergeladener Software entstehen, wird- soweit gesetzlich zulässig -
                ausgeschlossen.
            </p>
            <h2>Haftung für Links &amp; Verweise</h2>
            <p>
                Diese Internetseite enthält Links zu Webseiten Dritter, auf deren Inhalt das
                Land Hessen keinen Einfluss hat. Durch diese Links wird lediglich der Zugang
                zur Nutzung fremder Inhalte nach § 8 Telemediengesetz ermöglicht.
            </p>
            <p>
                Die Redaktionsleitung hat bei der erstmaligen Verknüpfung mit einem anderen
                Internetangebot den fremden Inhalt daraufhin geprüft, ob durch ihn eine
                mögliche zivilrechtliche oder strafrechtliche Verantwortlichkeit ausgelöst
                wird. Sobald festgestellt wird, dass ein bestimmtes Angebot, zu dem ein Link
                bereitgestellt wurde, eine zivil- oder strafrechtliche Verantwortlichkeit
                auslöst, wird der Verweis auf dieses Angebot unverzüglich aufgehoben, soweit
                dies technisch möglich und zumutbar ist.
            </p>
            <h2>Rechtswahl</h2>
            <p>Es gilt deutsches Recht.</p>

            <hr />

            <div>

                <h2>Webdesign, Hosting und Programmierung</h2>
                <a href="https://hessenapp.de" target="_blank" >
                    <img
                        className="col-12 col-md-4"
                        src="https://hessenapp.de/wp-content/uploads/2023/07/hessenapp_gmbh_icon-%E2%80%93-4-2048x450.png"
                        alt="Logo der Hessen App GmbH (Grünberg)"
                    />
                </a>
                <p>
                    Die Entwicklung und das Hosting dieser Website wurden von der Hessen App GmbH aus Grünberg übernommen, einem innovativen StartUp, das sich auf kommunale Projekte spezialisiert hat. Mit ihrem umfassenden Angebot in Webdesign, App-Entwicklung und Beratung, legt die Hessen App GmbH großen Wert auf individuell zugeschnittene Lösungen, die speziell auf die Anforderungen kommunaler Einrichtungen und Unternehmen abgestimmt sind. Ein Beispiel für ihre herausragende Arbeit ist die <a href="https://mittelhessen.app" target="_blank">Mittelhessen-App</a>, die mit innovativen Benutzeroberflächen und KI-basierter Automatisierung entwickelt wurde. Darüber hinaus gewährleistet die Hessen App GmbH durch ihre Hosting-Dienste in Deutschland höchste Standards in Qualität und Datenschutz.
                </p>
                <a href="https://hessenapp.de" target="_blank" >
                    <button>
                        Weiter zur Website
                    </button>
                </a>
            </div>
        </TextPageTemplate>
    )
}
export default ImpressumPage