import axios from "axios";

export async function alenaxios(request, config = {}) {

    return await axios(request, config)

    // console.log("alenaxios", request, config)
    // if (!request.method) request.method = "GET"
    // if (request.method != "GET") {
    //     console.log("alenaxios only supports GET requests")
    //     console.error("alenaxios only supports GET requests")
    // }

    // let cacheKey = request.url.replace("https://", "");

    // let cacheDurationInMinutes = request.cacheDurationInMinutes || 60
    // let cachedResponse = localStorage.getItem(cacheKey);

    // if (cachedResponse) {
    //     console.log("Using cached response for " + cacheKey)
    //     let cacheTimestamp = localStorage.getItem(cacheKey + ':t');
    //     let age = Date.now() / 1000 - cacheTimestamp;
    //     let cacheDurationInMilliseconds = cacheDurationInMinutes * 60000;

    //     if (age < cacheDurationInMilliseconds) {
    //         return Promise.resolve({ data: JSON.parse(cachedResponse) });
    //     } else {
    //         localStorage.removeItem(cacheKey);
    //         localStorage.removeItem(cacheKey + ':t');
    //     }
    // }


    // console.log("Making request for " + cacheKey)
    // console.log("request", request)
    // console.log("config", config)
    // return await axios(request, config)
    //     .then(response => {
    //         let responseToCache = JSON.stringify(response.data);
    //         localStorage.setItem(cacheKey, responseToCache);
    //         localStorage.setItem(cacheKey + ':t', Date.now() / 1000);
    //         // return response;
    //         return Promise.resolve(response);
    //     })
    //     .catch(err => {
    //         console.error("err");
    //         console.error(err);
    //         return Promise.resolve(err);
    //     })
}

export function alenaxiosGet(url, cacheDurationInMinutes = 60) {
    return alenaxios({
        method: "GET",
        url: url,
        cacheDurationInMinutes: cacheDurationInMinutes
    })
}