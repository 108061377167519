import axios from "axios"
import React, { useEffect, useState } from "react"
import { ServerAddress } from "../../../const/Server"
import "./ImportantWarnings.scss"

function ImportantWarnings({ }) {

    const [Error, setError] = useState(false)

    useEffect(() => {
        axios({ url: ServerAddress })
            .then(() => setError(false))
            .catch(() => setError(true))
    }, [])

    if (!Error) return
    return (
        <div className="ImportantWarnings">
            <span className="title">Internetverbindung fehlgeschlagen</span>
            <span className="desc">Es scheint, keine Verbindung zu HLFS-Sever zu geben. Prüfen Sie bitte Ihre Internetverbindung oder versuchen Sie es später erneut. </span>
        </div>
    )
}
export default ImportantWarnings