import React from "react"
import "./BorderBox.scss"

function BorderBox({ children, className = "" }) {
    return (
        <div className={"BorderBox " + className}>
            {children}
        </div>
    )
}
export default BorderBox