import { useMemo } from 'react';
import { EDUCATION_ENDPOINT, EDUCATION_WITH_SCENARIOS_ENDPOINT } from '../const/Endpoints';
import { useApi } from './API';


//Lädt alle Szenarien
// export const useEducationsFromUser = () => {
//     let { data = [], loading, error } = useApi(EDUCATION_ENDPOINT);

//     if (!Array.isArray(data)) data = []

//     return { data, loading, error };
// };



export const useOwnScenarios = ({ targetDepartmentId, }) => {


    let { data = [], loading, error } = useApi(EDUCATION_WITH_SCENARIOS_ENDPOINT, { targetDepartmentId: targetDepartmentId });

    // if (!Array.isArray(data)) data = []

    return { data, loading, error };
};


//Lädt alle Ausbildungstypen
export const useEducationsWithScenarios = ({ targetDepartmentId, }) => {


    let { data = [], loading, error } = useApi(EDUCATION_WITH_SCENARIOS_ENDPOINT, { targetDepartmentId: targetDepartmentId });

    // if (!Array.isArray(data)) data = []

    return { data, loading, error };
};

//Lädt alle Ausbildungstypen
export const useEducations = () => {
    let { data = [], loading, error } = useApi(EDUCATION_ENDPOINT);

    if (!Array.isArray(data)) data = []

    return { data, loading, error };
};


//Lädt alle Szenarien
export const useScenarios = ({ educationUri, scenarioGroupUri } = {}) => {
    const params = useMemo(() => ({
        ...(educationUri && { educationUri }),
        ...(scenarioGroupUri && { scenarioGroupUri }),
    }), [educationUri, scenarioGroupUri]);

    const { data = [], loading, error } = useApi('/scenarios', params);

    return { data, loading, error };
};

export const useScenariosInEducations = () => {
    const { data = [], loading, error } = useApi('/scenarios');
    return { data, loading, error };
};



//Lädt einen Ausbildungstyp inkl. aller Szenarien 
export const useEducation = (uri) => {
    let { data, loading, error } = useApi(EDUCATION_ENDPOINT + uri);

    if (Array.isArray(data)) {
        data = data[0]?.attributes;

        console.log(data)
        if (data?.scenario_groups?.data) {
            console.log("sort...")
            data.scenario_groups?.data?.sort((a, b) => {
                if (a.attributes.title < b.attributes.title) return -1;
                if (a.attributes.title > b.attributes.title) return 1;
                return 0;
            });
        }
    }

    return { data, loading, error };
};