import React, { useState } from "react"
import "./FaqButton.scss"

function FaqButton({ title, text, url }) {

    const [IsOpen, setIsOpen] = useState(false)

    function toggle() {
        setIsOpen(!IsOpen)
    }

    return (
        <div className="FaqButton" onClick={toggle}>
            <button className="ms-0">
                {title}
            </button>
            {IsOpen && <div>
                <p>{text}</p>
                {url && <p><a href={url}>Link öffnen</a></p>}
                <hr />
            </div>}
        </div>
    )
}
export default FaqButton