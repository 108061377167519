import React, { useCallback, useEffect, useState } from "react";
import { useCast } from 'react-chromecast';
import { useLocation, useNavigate } from "react-router-dom";
import { onGoogleChrome } from "../../../const/Helper";
import { IFRAME_PERMISSIONS } from "../../../const/Server";
import { GetWebviewUrlFromFilePrefix } from "../../../service/Strapi";
import "./WebViewPage.scss";

function WebViewPage() {
    const navigate = useNavigate();
    const [elapsedTime, setElapsedTime] = useState(0);
    const startTime = React.useRef(new Date()).current;
    const [isFullScreen, setIsFullScreen] = useState(false);

    const { state } = useLocation();
    let { title, publicId, image, description, documents, play, notice, duration, filePrefix } = state?.scenario || {}

    const goBack = () => {
        navigate(-1);
    }

    // Aktualisiere die auf der Seite verbrachte Zeit jede Sekunde
    useEffect(() => {
        const timer = setInterval(() => {
            setElapsedTime(Math.floor((new Date() - startTime) / (1000 * 60)));
        }, 1000 * 60);
        return () => {
            clearInterval(timer);
        };
    }, [startTime]);

    const url = GetWebviewUrlFromFilePrefix(filePrefix)

    const toggleFullScreen = () => {
        const iframe = document.getElementById('framelive');
        if (iframe) {
            if (document.fullscreenElement) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
                setIsFullScreen(false);
            } else {
                if (iframe.requestFullscreen) {
                    iframe.requestFullscreen();
                } else if (iframe.mozRequestFullScreen) {
                    iframe.mozRequestFullScreen();
                } else if (iframe.webkitRequestFullscreen) {
                    iframe.webkitRequestFullscreen();
                } else if (iframe.msRequestFullscreen) {
                    iframe.msRequestFullscreen();
                }
                setIsFullScreen(true);
            }
        }
    }


    return (
        <div className="WebViewPage">
            <div className="menu">
                <div title="Zurück" className="back" onClick={goBack} >
                    <div className="px-2 py-1" title="Zurück">
                        <i className="fa-solid fa-angle-left" />
                    </div>
                    <span className="ps-2" title="Zurück">
                        {title || "E: Titel nicht verfügbar."} {filePrefix}
                    </span>
                </div>

                <span className="elapsedTime" title="Minuten seit Start">Erkundungsdauer: {elapsedTime} min</span>

                <div className="d-flex flex-row">
                    <CastButton>
                        <i className="fa-brands fa-chromecast"></i>
                    </CastButton>

                    <button className="" title="Vollbild" onClick={toggleFullScreen}>
                        <i className="fa-solid fa-expand"></i>
                    </button>
                </div>

            </div>

            {/* ODer: allow="vr,gyroscope,accelerometer" */}
            <iframe id="framelive"
                allow={IFRAME_PERMISSIONS}
                mozallowfullscreen="true"
                webkitallowfullscreen="true"
                frameborder="0"
                allowfullscreen
                allowvr="yes"
                src={url || "/404"}
            />
        </div>
    )
}
export default WebViewPage


function CastButton(props) {
    if (!onGoogleChrome) return null;
    return CastButtonInner(props);
}

function CastButtonInner({ children }) {
    const cast = useCast({
        initialize_media_player: "framelive",
        auto_initialize: true,
    })
    const handleClick = useCallback(async () => {
        if (cast.castReceiver) {
            await cast.handleConnection();
        }
    }, [cast.castReceiver, cast.handleConnection])
    return (
        <button title="Mit TV verbinden (Chromecast)" onClick={handleClick}>{children}</button>
    )
}