import React from "react"
import "./WarnBox.scss"

function WarnBox({ children }) {
    if (!children) return
    return (
        <div className="WarnBox">
            <span className="notice">
                <div className="pill"></div>
                <span><i className="fa-solid fa-triangle-exclamation"></i> Hinweis:</span>
                {children}
            </span>
        </div>
    )
}
export default WarnBox