import React from "react";
import { Link } from "react-router-dom";
import { useServicePageData } from "../../../service/ServicePageService";
import { GetImageSource } from "../../../service/Strapi";
import EditInStrapi from "../../atoms/boxes/EditInStrapi";
import TextPageTemplate from "../../templates/TextPageTemplate/TextPageTemplate";
import "./ServicePage.scss";

function ServicePage({ }) {


    const { servicePageData, loading, error } = useServicePageData();

    if (loading) return <div>Laden...</div>;
    if (error) return <div>Fehler beim Laden der Daten</div>;
    return (
        <TextPageTemplate title="Servicebereich" className="ServicePage">
            <EditInStrapi url="https://backend.hlfs-vr.de/admin/content-manager/collection-types/api::service-item.service-item" />

            {/* Downloads */}
            {servicePageData.map((i, k) => <ServicePageCategory {...i.attributes} key={k} />)}
            <Link to={"/debug"}>Debug-Informationen</Link>
        </TextPageTemplate >
    )
}
export default ServicePage

function ServicePageCategory({ title, service_items = {} }) {
    let items = service_items?.data || []
    return (
        <div className="mb-4 mt-5">
            <h2>{title}</h2>
            <div className="mb-2">
                {items.map((i, k) => <ServicePageItem {...i.attributes} key={k} />)}
            </div>
        </div>
    )
}

function ServicePageItem({ title, description, file }) {

    let url = GetImageSource(file)
    let size = false

    return (
        <a target="_blank" href={url}>
            <div className="pb-3" >
                <div className="d-flex align-items-center ">
                    <div className="p-2">
                        <span className="icon">📄</span>
                    </div>
                    <div>
                        <div>
                            {title && <h3 className="mb-0">{title}</h3>}
                            {description && <small>{description}</small>}
                        </div>
                        {size && < small className="size"> {(size + "").replace(".", ",")} MB</small>}
                    </div>
                </div>
            </div>
        </a>
    )
}