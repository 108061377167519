import { useEffect, useState } from "react";
import { StrapiAddress } from "../const/Server";
import { alenaxios } from "./Alenaxios";
import { STRAPI_HEADERS } from "./Strapi";


export const useServicePageData = () => {
    const [servicePageData, setServicePageData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        GetServicePageItems()
            .then(data => {
                setServicePageData(data);
                setLoading(false);
            })
            .catch(err => {
                console.error("Fehler beim Laden der Service-Page-Daten:", err);
                setError(err);
                setLoading(false);
            });
    }, []); // Leeres Array stellt sicher, dass der Effekt nur beim Mounten der Komponente ausgeführt wird.

    return { servicePageData, loading, error };
};


export function GetServicePageItems() {
    return alenaxios({
        method: "GET",
        url: StrapiAddress + "/service-item-kategories?populate[0]=service_items&populate[1]=service_items.file",
        headers: STRAPI_HEADERS
    })
        .then(res => {
            console.log(res.data)
            return res.data?.data
        })
        .catch(err => {
            console.log("Das FAQ konnte nicht von Strapi geladen werden.", err)
            return []
        })
}