import { StrapiAddress } from "../const/Server";
import { alenaxios } from "./Alenaxios";
import { STRAPI_HEADERS } from "./Strapi";

export function GetFaqButtons() {
    return alenaxios({
        method: "GET",
        url: StrapiAddress + "/faq-items",
        headers: STRAPI_HEADERS
    })
        .then(res => {
            return res.data?.data
        })
        .catch(err => {
            console.log("Das FAQ konnte nicht von Strapi geladen werden.", err)
            return []
        })
}