import React from 'react';
import './DefaultLoader.scss'; // Stil für die Animation

export const DefaultLoader = ({ icon = "circle-notch" }) => {

    //fa-cog for settings 
    return (
        <div className="DefaultLoader" >
            <i className={`fas fa-${icon} fa-spin`}></i>
            {/* <span>{"Seite lädt..."}</span> */}
        </div >
    );
};

export default DefaultLoader;
